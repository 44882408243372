import React from "react";

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const List = (props) => {
  return (
    <>
      {props.type === "ul" ? (
        <>
          {props.greenCheckmark && (
            <ul className={`${props.class} fa-ul`}>
              {props.items.map((item, index) => (
                <li
                  key={item.id}
                  className={item.class}
                  id={
                    props.accountName
                      ? `${props.accountName}-${item.id}`
                      : props.idList
                      ? `list-${props.idList}-item-${index + 1}`
                      : `item-${index + 1}`
                  }
                >
                  <span className="fa-li">
                    <FontAwesomeIcon
                      className={`${props.isCommercial ? "text-info" : "text-success"} mr-2`}
                      icon={["fas", "check"]}
                    />
                  </span>
                  <span dangerouslySetInnerHTML={{ __html: item.text }}></span>
                </li>
              ))}
            </ul>
          )}
          {props.greenCircleCheckmark && (
            <ul className={`${props.class} fa-ul`}>
              {props.items.map((item, index) => (
                <li
                  key={item.id}
                  className={item.class}
                  id={props.accountName ? `${props.accountName}-${item.id}` : `item-${index + 1}`}
                >
                  <span className="fa-li" style={{ fontSize: "1.2em", lineHeight: "1.25em" }}>
                    <FontAwesomeIcon
                      className={`${props.isCommercial ? "text-info" : "text-success"} mr-2`}
                      icon={["fas", "check-circle"]}
                    />
                  </span>
                  <span dangerouslySetInnerHTML={{ __html: item.text }}></span>
                </li>
              ))}
            </ul>
          )}
          {!props.greenCheckmark && !props.greenCircleCheckmark && (
            <ul id={props.accountName ? props.accountName : ""} className={props.class}>
              {props.items.map((item, index) => (
                <li
                  key={index}
                  className={item.class}
                  id={props.accountName ? `${props.accountName}-${item.id}` : `item-${index + 1}`}
                >
                  {item.icon && <FontAwesomeIcon className={`${item.icon.class} mr-2`} icon={item.icon} />}
                  {item.text && <span dangerouslySetInnerHTML={{ __html: item.text }} />}
                </li>
              ))}
            </ul>
          )}
        </>
      ) : props.greenCircleNumber ? (
        <ol className={`${props.class} green-circle-number`}>
          {props.list.map((item, index) => (
            <li
              key={index}
              className={item.class}
              id={props.accountName ? `${props.accountName}-${item.id}` : `item-${index + 1}`}
            >
              {item.text}
            </li>
          ))}
        </ol>
      ) : (
        <ol className={props.class}>
          {props.items.map((item, index) => (
            <li
              key={index}
              className={item.class}
              id={props.accountName ? `${props.accountName}-${item.id}` : `item-${index + 1}`}
            >
              {item.icon && <FontAwesomeIcon className={`${item.icon.class} mr-2`} icon={item.icon} />}
              {item.text && <span dangerouslySetInnerHTML={{ __html: item.text }} />}
            </li>
          ))}
        </ol>
      )}
    </>
  );
};
export default List;
/*
NOTE: The listClass "green-checkmark" has been deleted since we removed the FontAwesome font family in our CSS. 
For now, set the greenCheckmark flag to true and the list items will have a green checkmark icon applied above, 
and any icon specified in the item will be ignored.

The greenCircleNumber flag is used to set the ordered list class to include "green-circle-number" above, 
and any icon specified in the item will be ignored.
Conversely you could leave the greenCircleNumber flag as false and explicitly add "green-circle-number" to the listClass,
but do not set both the flag and the listClass.
*/
List.defaultProps = {
  type: "ul",
  class: "",
  greenCheckmark: false,
  greenCircleCheckmark: false,
  isCommercial: false,
  greenCircleNumber: false,
  accountName: "",
  items: [
    // {
    //     id: 1,
    //     class: "",
    //     text: "",
    //     icon: {}
    // }
  ]
};
