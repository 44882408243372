import React, { useEffect } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { useLanguageContext } from "../../contexts/language-context";

// Styles
import "./breadcrumb.bootstrap.scss";

/**
 * This is the component to render the breadcrumbs at the top for fixed pages.
 * 
 * @param {Object} props - The object containing props.
 * @param {string} props.containerClass - String with the container div classes.
 * @param {Object[]} props.data - Required array of objects to render the list of breadcrumbs.
 *   - `id`: required number.
 *   - `title`: required string to show.
 *   - `url`: optional string to make the breadcrumb a link.
 *   - `active`: boolean value to make the breadcrumb active page bold.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const Breadcrumb = ({
  containerClass = "",
  data = [
    {
      id: 1,
      url: "/relative-path", // all urls should be relative to the root
      title: "Path Title"
    },
    {
      id: 2,
      title: "Active Page Title",
      active: true // the active page (no url needed). Previously was the class name "active", but now a boolean (either will work).
    }
  ]
}) => {
  const isSpanish = useLanguageContext();
  const arrayData = data;
  const arrayLength = arrayData.length;

  useEffect(() => {
    if (arrayLength > 2) {
      for (let a = 0; a < arrayLength - 2; a++) {
        arrayData[a].class = "d-none d-md-inline-flex";
      }
    }

    // console.log("Breadcrumb data: ", data);
  }, []);

  return (
    <div className={`container ${containerClass}`}>
      <nav aria-label="breadcrumb" className="breadcrumb-nav">
        <ol className="breadcrumb breadcrumb-list">
          <li className={`breadcrumb-item ${arrayLength >= 2 ? "d-none d-md-inline-block" : ""}`}>
            <Link to={isSpanish ? "/es" : "/"} className="text-secondary text-decoration-none">
              WaFd Bank
            </Link>
          </li>
          {arrayData.map((crumb) => {
            let activeClass = crumb.active ? "active" : "";
            return (
              <li key={crumb.id} className={`breadcrumb-item breadcrumb-list-item ${activeClass} ${crumb.class}`}>
                {crumb.active ? (
                  <span dangerouslySetInnerHTML={{ __html: crumb.title }} />
                ) : (
                  <Link className={`text-secondary breadcrumb-link text-decoration-none ${crumb.class}`} to={crumb.url}>
                    <span dangerouslySetInnerHTML={{ __html: crumb.title }} />
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};
export default Breadcrumb;

// NOTE: the following defaultProps will result in a breadcrumb: "WaFd Bank / Path Title / Active Page Title"
// where 'Active Page Title' is the active page. All non-active pages will be a Link to those urls.
Breadcrumb.propTypes = {
  containerClass: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string, // all urls should be relative to the root
      title: PropTypes.string.isRequired,
      active: PropTypes.bool // the active page (no url needed). Previously was the class name "active", but now a boolean (either will work).
    })
  )
};

