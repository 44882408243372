import React, { Fragment } from "react";

import List from "../custom-widgets/list";
import Button from "../custom-widgets/button";

// Styles
import "./hero-chevron-body.bootstrap.scss";

// Allow HTML in text for typographical HTML entities (ampersand, em-dash, double quotes, etc.) in components
const Heading = (heading) => (
  <div className={`${heading.class} hero-heading`} dangerouslySetInnerHTML={{ __html: heading.text }}></div>
);
const Subheading = (subheading) => (
  <div className={`${subheading.class} hero-subheading`} dangerouslySetInnerHTML={{ __html: subheading.text }}></div>
);
const Paragraph = (paragraph) => (
  <div className={`${paragraph.class} hero-paragraph`} dangerouslySetInnerHTML={{ __html: paragraph.text }}></div>
);
const TertiaryText = (tertiaryText) => (
  <div
    className={`${tertiaryText.class} hero-tertiary-text`}
    dangerouslySetInnerHTML={{ __html: tertiaryText.text }}
  ></div>
);

const HeroChevronBody = (props) => {
  // console.log("HeroChevronBody props: ", props);
  const getComponent = (component) => {
    if (component.type === "heading") {
      return <Heading {...component} />;
    } else if (component.type === "subheading") {
      return <Subheading {...component} />;
    } else if (component.type === "paragraph") {
      return <Paragraph {...component} />;
    } else if (component.type === "tertiaryText") {
      return <TertiaryText {...component} />;
    } else if (component.type === "button") {
      return <Button {...component} />;
    } else {
      return component;
    }
  };

  return (
    <div className="hero-chevron-body">
      <div className="container">
        <div className={`row ${props.rowClass}`}>
          {/* COLUMNS */}
          {props.columns &&
            props.columns.map((column, index1) => {
              return (
                <div key={column.id || `hero-column-${index1}`} className={`${column.class}`}>
                  {column.contentPlaceholder && <div id={`hero-content-placeholder-${index1}`}></div>}
                  {column.components &&
                    column.components.map((component, index2) => {
                      return <Fragment key={component.id || `component-${index2}`}>{getComponent(component)}</Fragment>;
                    })}
                </div>
              );
            })}
          {/* NO COLUMNS */}
          {!props.columns && (
            <div className={props.colClass}>
              {props.contentPlaceholder && <div id="hero-content-placeholder"></div>}
              {props.components &&
                props.components.map((component, index3) => {
                  return (
                    <Fragment key={component.id || `component-${index3}`}>
                      {component.heading && <Heading {...component.heading} />}
                      {component.subheading && <Subheading {...component.subheading} />}
                      {component.paragraph && <Paragraph {...component.paragraph} />}
                      {component.tertiaryText && <TertiaryText {...component.tertiaryText} />}
                      {component.list && <List {...component.list} />}
                      {component.button && <Button {...component.button} />}
                    </Fragment>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default HeroChevronBody;

HeroChevronBody.defaultProps = {
  colClass: "col-sm-12 col-md-5", // Please try NOT to override this. Work with designers to get new images if the text is overlapping with objects on the image.
  rowClass: "",
  // NOTE: components[] and contentPlaceholder may be at the props root level or inside each column, but not both.
  columns: null,
  // [
  //   {
  //      class: 'col-sm-12 col-md-5',
  //      contentPlaceholder: true
  //      components: [
  //        {
  //          type: "heading", // NOTE: type is required
  //          text: "WaFd Bank"
  //        }
  //      ]
  //   },
  //   {
  //      class: 'col-sm-12 col-md-8',
  //      contentPlaceholder: false
  //      components: [
  //        ...
  //      ]
  //    }
  // ]
  contentPlaceholder: false, // this will be true for 3rd Party injection of content
  components: null
  // [
  //   {
  //     heading: {
  //         id: 1, // NOTE: id is required
  //         class: '',
  //         text: ''
  //     },
  //     subheading: {
  //         id: 2,
  //         class: '',
  //         text: ''
  //     },
  //     paragraph: {
  //         id: 3,
  //         class: '',
  //         text: ''
  //     },
  //     tertiaryText: {
  //         id: 4,
  //         class: '',
  //         text: ''
  //     },
  //     button: {
  //         ...
  //     },
  //     <AnyRenderableComponent />
  //   }
  // ]
};
