import React from "react";
import PropTypes from "prop-types";
import { useLanguageContext } from "../../contexts/language-context";

// Styles
import "./notifications.bootstrap.scss";

import Icon from "../custom-widgets/icon";

const MortgageNotificationBar = (props) => {
  const isSpanish = useLanguageContext();

  return (
    <div id="mortgage-notification-bar" className="bg-blue-10 my-0 d-flex" style={{ minHeight: "44px" }}>
      <div className="container notification-text h5-font-size d-flex align-items-start justify-content-center py-2">
        <Icon class="text-primary mt-1 mr-3 fa-w-18" name="info-circle" lib="fas" />
        <div>
          {isSpanish
            ? "Los préstamos para Remodelación, Lotes y Construcción no están disponibles en California."
            : props.loanType + " are currently not available in the state of California."}
        </div>
      </div>
    </div>
  );
};
export default MortgageNotificationBar;

MortgageNotificationBar.defaultProps = {
  loanType: "Home Loans"
};
MortgageNotificationBar.propTypes = {
  loanType: PropTypes.string
};
