import React from "react";
import { Link } from "gatsby";
import { useLanguageContext } from "../../contexts/language-context";
import { Fragment } from "react";

const ServiceStatesLinks = (props) => {
  const isSpanish = useLanguageContext();
  let stateDataLocal = props.stateData;

  if (!props.hasTexasLink) {
    stateDataLocal = stateDataLocal.filter((n) => n.enName !== "Texas");
  }

  return (
    <span className={props.class}>
      {stateDataLocal.map((obj, index) => (
        <Fragment key={index}>
          <Link id={obj.id} className={props.darkBackground ? "text-white" : ""} to={isSpanish ? obj.esUrl : obj.enUrl}>
            {isSpanish ? obj.esName : obj.enName}
          </Link>
          {index === stateDataLocal.length - 2
            ? // Append ", and" before the last state
              `${isSpanish ? ", y " : ", and "}`
            : index === stateDataLocal.length - 1
            ? // Append nothing after the last state
              ""
            : // Append ", " after each state
              ", "}
        </Fragment>
      ))}
    </span>
  );
};
export default ServiceStatesLinks;

ServiceStatesLinks.defaultProps = {
  class: "",
  hasTexasLink: true,
  darkBackground: false,
  stateData: [
    {
      id: "service-state-link-arizona",
      enName: "Arizona",
      enUrl: "/locations/arizona",
      esName: "Arizona",
      esUrl: "/es/sucursales/arizona"
    },
    {
      id: "service-state-link-california",
      enName: "California",
      enUrl: "/locations/california",
      esName: "California",
      esUrl: "/es/sucursales/california"
    },
    {
      id: "service-state-link-idaho",
      enName: "Idaho",
      enUrl: "/locations/idaho",
      esName: "Idaho",
      esUrl: "/es/sucursales/idaho"
    },
    {
      id: "service-state-link-nevada",
      enName: "Nevada",
      enUrl: "/locations/nevada",
      esName: "Nevada",
      esUrl: "/es/sucursales/nevada"
    },
    {
      id: "service-state-link-new-mexico",
      enName: "New Mexico",
      enUrl: "/locations/new-mexico",
      esName: "Nuevo Mexico",
      esUrl: "/es/sucursales/nuevo-mexico"
    },
    {
      id: "service-state-link-oregon",
      enName: "Oregon",
      enUrl: "/locations/oregon",
      esName: "Oregon",
      esUrl: "/es/sucursales/oregon"
    },
    {
      id: "service-state-link-texas",
      enName: "Texas",
      enUrl: "/locations/texas",
      esName: "Texas",
      esUrl: "/es/sucursales/texas"
    },
    {
      id: "service-state-link-utah",
      enName: "Utah",
      enUrl: "/locations/utah",
      esName: "Utah",
      esUrl: "/es/sucursales/utah"
    },
    {
      id: "service-state-link-washington",
      enName: "Washington",
      enUrl: "/locations/washington",
      esName: "Washington",
      esUrl: "/es/sucursales/washington"
    }
  ]
};
