import React from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

import { englishToSpanish, spanishToEnglish } from "../../data/url-alternates-data";
import { useLanguageContext } from "../../contexts/language-context";

const MAX_TITLE_LENGTH = 60;

/**
 * transformTitle takes a title and a boolean to determine if the suffix should be ignored.
 * It returns the given title with the suffix | WaFd Bank.
 * It also sanitizes the title of any existing suffix if it's provided.
 *
 * @param {string} title
 * @param {boolean} ignoreSuffix
 * @returns
 */
const transformTitle = (title, addSuffix) => {
  const baseTitle = title.replace(/ ?\| ?WaFd Bank/i, "");
  const suffixedTitle = baseTitle + " | WaFd Bank";

  if (addSuffix) {
    return suffixedTitle;
  } else {
    return baseTitle;
  }
};

/**
 * SEO component for setting meta tags, title, and schema data.
 *
 * @param {Object} props
 * @param {Object[]} props.meta - Array of meta tags in the shape { name?: string, property: string, content: string }
 * @param {string} props.title - Title of the page
 * @param {object} props.schema - Schema.org structured data
 * @param {boolean} props.addSuffix - If true, the suffix " | WaFd Bank" will be added to the title. Default true.
 * @returns
 */
const SEO = (props) => {
  // TODO: Check if this is unnecessary, we could remove it after a few runs.
  if (typeof props.meta !== "object" && !Array.isArray(props.meta)) {
    throw new Error("SEO.js: Meta data must be an array of objects.");
  }

  const title = transformTitle(props.title, props.addSuffix);
  const meta = [...props.meta];
  const schema = { ...props.schema };
  const ogTitleObject = meta.find((tag) => tag.property === "og:title");
  const ogImageObject = meta.find((tag) => tag.property === "og:image");
  const hasOgImage = !!ogImageObject; // We are checking if the find resulted in an object (true) or undefined (false).
  const hasOgTitle = !!ogTitleObject; // Same as above. This is a JS shortcut to " object !== undefined ? true : false "

  const isSpanish = useLanguageContext();
  const path = decodeURI(useLocation().pathname).replace(/\/$/, ""); // Remove trailing slash (/) from the URL
  const urlMap = isSpanish ? spanishToEnglish : englishToSpanish;
  const urlInOtherLanguage = urlMap[path] || "";
  const defaultOgImage = "https://www.wafdbank.com/images/wafd-logo.png";

  const isTitleTooLong = props.title.length > MAX_TITLE_LENGTH;

  if (ogTitleObject && ogTitleObject.content !== props.title) {
    console.warn("SEO.js: og:title and title are not the same for page: " + path + ". Overriding with Title.");
  }

  if (isTitleTooLong) {
    console.warn(
      'SEO.js: Title for "' +
        (path || "/") +
        '" is too long at ' +
        props.title.length +
        ' chars: "' +
        props.title +
        '".'
    );
  }

  // Blogs have headlines. These should be what is in the H1 of the page. These had the suffix, so we are appending it here.
  if (schema.headline && !schema.headline.includes(" | WaFd Bank")) {
    schema.headline += " | WaFd Bank";
  }

  if (hasOgImage) {
    ogImageObject.content = ogImageObject.content || defaultOgImage;
  } else {
    meta.push({
      property: "og:image",
      content: defaultOgImage
    });
  }

  if (hasOgTitle) {
    ogTitleObject.content = title;
  } else {
    meta.push({
      name: "title",
      property: "og:title",
      content: title
    });
  }

  // We don't have any og:image:width or og:image:height tags in the meta data across the site, so we're just setting it here.
  meta.push({
    property: "og:image:width",
    content: "250"
  });
  meta.push({
    property: "og:image:height",
    content: "250"
  });

  const urlData = urlInOtherLanguage
    ? [
        {
          rel: "alternate",
          href: "https://www.wafdbank.com" + path,
          hreflang: isSpanish ? "es-us" : "en-us"
        },
        {
          rel: "alternate",
          href: "https://www.wafdbank.com" + urlInOtherLanguage,
          hreflang: isSpanish ? "en-us" : "es-us"
        }
      ]
    : undefined; // Preventing the render of this property if page has no alternate language version.

  return (
    <Helmet
      htmlAttributes={{ lang: isSpanish ? "es" : "en" }}
      defaultTitle="WaFd Bank"
      title={title}
      link={urlData}
      meta={meta}
    >
      {Object.keys(schema).length !== 0 && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Helmet>
  );
};

SEO.defaultProps = {
  meta: [],
  title: "WaFd Bank",
  schema: {},
  addSuffix: true
};

SEO.propTypes = {
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      property: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  title: PropTypes.string.isRequired,
  schema: PropTypes.object,
  addSuffix: PropTypes.bool
};

export default SEO;
