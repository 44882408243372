import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import HeroChevronBody from "./hero-chevron-body";

// Styles
import styles from "./hero-chevron.module.scss";
import "./hero-chevron.bootstrap.scss";

const ChevronOverlay = (props) => {
  const chevronColorMap = {
    "bg-success": "chevron-green",
    "bg-green-60": "chevron-green",
    "bg-primary": "chevron-blue",
    "bg-blue-60": "chevron-blue",
    "bg-gray-20": "chevron-light-gray",
    "bg-info": "chevron-dark-green",
    "bg-commercial-green": "chevron-dark-green",
    "bg-dark-green-00": "chevron-very-dark-green",
    "bg-dark-green-01": "chevron-dark-green",
    "bg-warning": "chevron-yellow",
    "bg-yellow-00": "chevron-yellow",
    "bg-white": "chevron-white",
    "bg-kraken-ice-blue": "chevron-kraken-ice-blue",
    "bg-kraken-primary-blue": "chevron-kraken-primary-blue"
  };
  const chevronClass = chevronColorMap[props.chevronBgClass];
  return (
    <div className={`${props.chevronBgClass} ${styles.chevronContainer}`}>
      <div className={`${chevronClass} ${styles.chevronTop}`} />
      <div className={`${chevronClass} ${styles.chevronBottom}`} />
    </div>
  );
};

const HeroChevron = (props) => {
  //console.log("HeroChevron props: ", props);

  /* ------------------------------------------------------------------------
  STANDARD HERO IMAGE SIZES
    XXS: <420px (320px min supported) - image size: 300px * 125px (new breakpoint)
    XS: 420-599px - image size: 420px * 200px (new breakpoint and image height)
    SM: 600-767px - image size: 500px * 250px
    MD: 768-991px - image size: 550px * 300px
    LG: 992-1199px - image size: 700px * 350px
    XL: 1200-1919px - image size: 1100px * 350px
    XXL: >=1920 (2500px max supported) - image size: 1400px * 350px
  ---------------------------------------------------------------------------
  NOTE: The standard naming conventions for chevron hero image fragments and image props are:
    "heroChevronImageFragmentXXS" --> "props.imgVariableXXS"  
    "heroChevronImageFragmentXS"  --> "props.imgVariableXS"
    "heroChevronImageFragmentSM"  --> "props.imgVariableSM"
    "heroChevronImageFragmentMD"  --> "props.imgVariableMD"
    "heroChevronImageFragmentLG"  --> "props.imgVariableLG"
    "heroChevronImageFragmentXL"  --> "props.imgVariableXL"
    "heroChevronImageFragmentXXL" --> "props.imgVariableXXL" 
  ----------------------------------------------------------------------------
  NOTE: the inline styles for "maxHeight" are necessary to keep the containing block 
  from overflowing below the hero image height. Some strange quirk with the positioning.
  ------------------------------------------------------------------------- */

  return (
    <div id={props.id} className={`hero hero-chevron ${styles.chevron}`}>
      {/* XXS */}
      {props.imgVariableXXS && (
        <div className="d-block d-xs420-none" style={{ maxHeight: "125px" }}>
          <ChevronOverlay chevronBgClass={props.chevronBgClass} />
          <div id="hero-chevron-image-container-xxs" className={styles.imageContainer}>
            <GatsbyImage
              loading="eager"
              image={props.imgVariableXXS}
              className={styles.chevronImage}
              alt={props.altText}
            />
          </div>
        </div>
      )}
      {/* XS */}
      {props.imgVariableXS && (
        <div className="d-none d-xs420-block d-sm-none" style={{ maxHeight: "200px" }}>
          <ChevronOverlay chevronBgClass={props.chevronBgClass} />
          <div id="hero-chevron-image-container-xs" className={styles.imageContainer}>
            <GatsbyImage
              loading="eager"
              image={props.imgVariableXS}
              className={styles.chevronImage}
              alt={props.altText}
            />
          </div>
        </div>
      )}
      {/* SM */}
      {props.imgVariableSM && (
        <div className="d-none d-sm-block d-md-none" style={{ maxHeight: "250px" }}>
          <ChevronOverlay chevronBgClass={props.chevronBgClass} />
          <div id="hero-chevron-image-container-sm" className={styles.imageContainer}>
            <GatsbyImage
              loading="eager"
              image={props.imgVariableSM}
              className={styles.chevronImage}
              alt={props.altText}
            />
          </div>
        </div>
      )}
      {/* MD */}
      {props.imgVariableMD && (
        <div className="d-none d-md-block d-lg-none" style={{ maxHeight: "300px" }}>
          <ChevronOverlay chevronBgClass={props.chevronBgClass} />
          <div id="hero-chevron-image-container-md" className={styles.imageContainer}>
            <GatsbyImage
              loading="eager"
              image={props.imgVariableMD}
              className={styles.chevronImage}
              alt={props.altText}
            />
          </div>
        </div>
      )}
      {/* LG */}
      {props.imgVariableLG && (
        <div className="d-none d-lg-block d-xl-none" style={{ maxHeight: "350px" }}>
          <ChevronOverlay chevronBgClass={props.chevronBgClass} />
          <div id="hero-chevron-image-container-lg" className={styles.imageContainer}>
            <GatsbyImage
              loading="eager"
              image={props.imgVariableLG}
              className={styles.chevronImage}
              alt={props.altText}
            />
          </div>
        </div>
      )}
      {/* XL */}
      {props.imgVariableXL && (
        <div className="d-none d-xl-block d-xxl-none" style={{ maxHeight: "350px" }}>
          <ChevronOverlay chevronBgClass={props.chevronBgClass} />
          <div id="hero-chevron-image-container-xl" className={styles.imageContainer}>
            <GatsbyImage
              loading="eager"
              image={props.imgVariableXL}
              className={styles.chevronImage}
              alt={props.altText}
            />
          </div>
        </div>
      )}
      {/* XXL */}
      {props.imgVariableXXL && (
        <div className="d-none d-xxl-block" style={{ maxHeight: "350px" }}>
          <ChevronOverlay chevronBgClass={props.chevronBgClass} />
          <div id="hero-chevron-image-container-xxl" className={styles.imageContainer}>
            <GatsbyImage
              loading="eager"
              image={props.imgVariableXXL}
              className={styles.chevronImage}
              alt={props.altText}
            />
          </div>
        </div>
      )}

      {/* HERO CHEVRON BODY */}
      {props.heroBody && <HeroChevronBody {...props.heroBody} />}
    </div>
  );
};
export default HeroChevron;

HeroChevron.defaultProps = {
  id: "default-hero-chevron",
  altText: "WaFd Bank",
  chevronBgClass: "bg-success",
  heroBody: null,
  // Chevron Hero sizes for all breakpoints
  imgVariableXXS: null,
  imgVariableXS: null,
  imgVariableSM: null,
  imgVariableMD: null,
  imgVariableLG: null,
  imgVariableXL: null,
  imgVariableXXL: null
};
