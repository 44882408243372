/**
 * Returns the hero image variables with the given sizes.
 * Requires the graphql names to be "imgVariableXS", "imgVariableSM", etc.
 * If a passed size is not found in the imgData, the next largest image size
 * will be returned as a fallback for missing images.
 *
 * To use with carousel hero images, pass the slideNumber (1, 2, 3, etc..)
 * @param {Object} imgData GraphQL query result
 * @param {string[]} sizes - Available sizes: "XXS", "XS", "SM", "MD", "LG", "XL", "XXL"
 * @param {number} [slideNumber] 1 - N (optional: use only for carousel heroes)
 * @returns {{}}
 */
const getHeroImgVariables = (imgData, sizes = ["XXS", "XS", "SM", "MD", "LG", "XL", "XXL"], slideNumber = "") => {
  //console.log(imgData);
  const sizesAvailable = ["XXS", "XS", "SM", "MD", "LG", "XL", "XXL"];

  const getNextLargestImgVar = (sizeWanted) => {
    const pos = sizesAvailable.indexOf(sizeWanted);
    if (pos > -1) {
      for (let i = pos; i < sizesAvailable.length; i++) {
        const key = "imgVariable" + slideNumber + sizesAvailable[i];
        const img = imgData[key]?.childImageSharp?.gatsbyImageData;
        // console.log("img[" + key + "]: ", img);
        if (img !== undefined) {
          return img;
        }
        /* eslint-disable-next-line */
        console.warn("No hero image data found for size " + sizeWanted + "!");
      }
    }
    /* eslint-disable-next-line */
    console.warn("No hero image data found for size " + sizeWanted + " and up!");
    return null;
  };

  // TODO: figure out a way to log the filepath of the hero images so when one fails we know which one it is.
  let retObj = {};
  for (let i = 0; i < sizes.length; i++) {
    const size = sizes[i].toUpperCase();
    const key = "imgVariable" + slideNumber + size;
    retObj[key] = getNextLargestImgVar(size);
  }
  // console.log("retObj: ", retObj);
  return retObj;
};

export default getHeroImgVariables;
